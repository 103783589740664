import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector, AppDispatch } from './store';
import Back from './main/Back'

import { setChangeView } from './slice/viewSlice';

import { format } from 'date-fns';
import {
    isAfterEqual,
    reactTagManagerEvCampaignClickFaqBtn,
    reactTagManagerEvDetailBeforeClickFaqBtn,
    reactTagManagerEvDetailAfterClickFaqBtn,
    reactTagManagerEvAppConfirmClickFaqBtn,
    reactTagManagerEvClickAppConfirmCompleteFaqBtn
} from '../functions/function';
import { reloadIframe } from './main/Faq'

const Header:React.FC = () => {
    const dispatch:AppDispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.viewState);
    const campaignData:any = useSelector((state) => state.campaignData);
    const confirmState:Number = useSelector((state) => state.viewData.confirmState);

    const today = new Date(format(new Date(), 'yyyy/MM/dd'));

    const returnBtnClick = () => {
        dispatch(setChangeView({viewState:0, prevViewState: viewState}));
    };

    // const displayKokumin = (auth.data.res_data.extarnal_org_id === '8113');

    const faqClick = () => {
        if(viewState === 0){
            reactTagManagerEvCampaignClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && !campaignData.is_entry && !isAfterEqual(today,campaignData.app_period_end)){
            reactTagManagerEvDetailBeforeClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 1 && campaignData.is_entry){
            reactTagManagerEvDetailAfterClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState !== 2){
            reactTagManagerEvAppConfirmClickFaqBtn(auth.data.res_data.send_id);
        }else if(viewState === 2 && confirmState === 2){
            reactTagManagerEvClickAppConfirmCompleteFaqBtn(auth.data.res_data.send_id);
        }

        if(viewState !== 3){
            dispatch(setChangeView({viewState:3, prevViewState: viewState}));
        } else {
            reloadIframe();
        }
    };

    if(auth.status !== 'loading' && auth.data.res_code === 200){

        // const displayWelcia = (auth.data.res_data.extarnal_org_id === '8112');
        const displayWelcia = false;

        return (
            <header>
                <div className='flex mx-auto items-center headerWrap'>
                    <div className='topLogoImg'>
                        <img src={`${process.env.REACT_APP_IMG_URL}${auth.data.res_data.banner_url}`} className='w-full'/>
                    </div>
                    <h1 onClick={returnBtnClick}>WAON POINT スタンプ</h1>
                    <div className='topQuestionImg'>
                        <img src='img/question.png' className='w-full' onClick={faqClick}/>
                    </div>
                    <br/>
                </div>
                {displayWelcia && (<div>
                    <div className="notice-info">
                        <p>
                            スタンプが貯まる対象のアプリバーコード
                        </p>
                        <p>
                            <span className="notice-target">〈対象〉
                                <span className="icon-wrapper">
                                    <img src="img/circle_icon.png" className="notice-icon" alt="square icon"/>
                                </span>
                                 ウエルシアグループアプリ
                            </span>
                            <span className="icon-wrapper">
                                <img src="img/welcia.png" className="notice-icon" alt="square icon"/>
                            </span><br/>
                            <span className="notice-not-target">
                                〈対象外〉
                                <span className="icon-wrapper">
                                    <img src="img/cross_icon.png" className="notice-icon" alt="square icon"/>
                                </span>
                                <span className='not-target-text'> iAEONアプリ</span>
                                <span className="icon-wrapper">
                                    <img src="img/iAEON.png" className="notice-icon" alt="square icon"/>
                                </span>・
                                <span className="icon-wrapper">
                                    <img src="img/cross_icon.png" className="notice-icon" alt="square icon"/>
                                </span>
                                <span className='not-target-text'>コクミンアプリ </span>
                                <span className="icon-wrapper">
                                    <img src="img/kokumin.png" className="notice-icon" alt="square icon"/>
                                </span>
                            </span>など
                        </p>
                    </div>
                </div>
                )}
                {viewState === 3? <Back /> : <></>}
            </header>
        );
    }else{
        return <></>;
    }
}

export default Header;